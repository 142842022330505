import { environment } from "@/src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";
// import * as pako from 'pako';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  constructor(private http: HttpClient) {}

  query(query: string, variables?: any): Observable<any> {
    const body = { query, variables };

    return this.http.post(`${environment.apiUrlLabelTraxxService}/graphql`, body);
    
    
    // .pipe(
    //   map((response:any) => {
    //     // Uncompress response
    //     console.log('Response: ', response)
    //     // const unzippedResponse = this.uncompress(response.body); // Access response body for decompression
    //     // console.log('Unzipped Response', unzippedResponse)
    //     // return unzippedResponse;
    //     return response;
    //   }),
    //   catchError(error => {
    //     console.error('GraphQL query failed:', error);
    //     return of(null);
    //   })
    // );
    
    // try {
    //   const response = await this.http.post<any>(`${environment.apiUrlLabelTraxxService}/graphql`, body);
    //   return response;
    // } catch (error) {
    //   throw new Error(`GraphQL query failed: ${error}`);
    // }
  }

  // private uncompress(buffer: ArrayBuffer): any {
  //   try {
  //     // Convert ArrayBuffer to Uint8Array
  //     const uint8Array = new Uint8Array(buffer);
  //     // Decompress using pako
  //     const inflated = pako.inflate(uint8Array, { to: 'string' });
  //     console.log(inflated);
  //     // Parse the JSON string
  //     const jsonResponse = JSON.parse(inflated);
  //     return jsonResponse;
  //   } catch (error) {
  //     console.error('Error while decompressing response:', error);
  //     return null;
  //   }
  // }
}