import { Injectable, TemplateRef } from "@angular/core";

export interface Toast {
  // header: string;
  // body: string;
  // delay?: number;
  // template: any;
  header: string;
  body: string;
	classname?: string;
	delay?: number;
}

@Injectable({ providedIn: 'root' })
export class AppToastService {
  toasts: Toast[] = [];

  show(toast: Toast) {
    this.toasts.push(toast);
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t != toast);
  }

  clear() {
		this.toasts.splice(0, this.toasts.length);
	}
}